import { Spin } from 'antd';
import Layout from 'antd/lib/layout/layout';
import { User } from 'firebase/auth';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { no404Retry } from './api';
import { getUserByUID } from './api/users';
import Navigation from './components/Navigation';
import NotFoundPage from './components/PageNotFound';
import { auth } from './util/firebase';
import Analytics from './views/Analytics';
import ClientDetails from './views/ClientDetails';
import Clients from './views/Clients';
import Dashboard from './views/Dashboard';
import EditUIMessage from './views/EditUIMessage';
import ImportIntegrations from './views/ImportIntegrations';
import ImportIntegrationsConfirmation from './views/ImportIntegrationsConfirmation';
import InvoiceDetails from './views/InvoiceDetails';
import Login from './views/Login';
import NewIntegrations from './views/NewIntegrations';
import NewUIMessage from './views/NewUIMessage';
import OrganizationDetails from './views/OrganizationDetails';
import Organizations from './views/Organizations';
import UIMessages from './views/UIMessages';
import UserDetails from './views/UserDetails';
import Users from './views/Users';

export const queryClient = new QueryClient();

function App() {
    const [initialized, setInitialized] = useState(false);
    const [, setUser] = useState<User | null>(null);

    auth.onAuthStateChanged((user) => {
        setInitialized(true);
        setUser(user);
    });

    const { data: user } = useQuery(
        ['user', auth.currentUser?.uid],
        () => getUserByUID(auth.currentUser!.uid),
        {
            enabled: !!auth.currentUser,
            retry: no404Retry,
        }
    );

    if (initialized && auth.currentUser === null) return <Login />;

    if (!user)
        return (
            <Layout style={{ padding: '32px 48px', background: '#fff' }}>
                <Spin size="large" />
            </Layout>
        );

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Layout style={{ minHeight: '100vh' }}>
                    <Navigation user={user} />
                    <Layout
                        style={{ padding: '32px 48px', background: '#fff' }}
                    >
                        <Routes>
                            {user?.role === 'FABRICAI_BILLING' ? (
                                <>
                                    <Route
                                        path="/"
                                        element={
                                            <Navigate to="/organizations" />
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Route
                                        path="/"
                                        element={<Navigate to="/clients" />}
                                    />
                                    <Route
                                        path="/clients"
                                        element={<Clients />}
                                    />
                                    <Route
                                        path="/clients/:id"
                                        element={<ClientDetails />}
                                    />
                                    <Route
                                        path="/invoiceQuery"
                                        element={<InvoiceDetails />}
                                    />
                                    <Route path="/users" element={<Users />} />
                                    <Route
                                        path="/users/:id"
                                        element={<UserDetails />}
                                    />
                                    <Route
                                        path="/uiMessages"
                                        element={<UIMessages />}
                                    />
                                    <Route
                                        path="/uiMessages/new"
                                        element={<NewUIMessage />}
                                    />
                                    <Route
                                        path="/uiMessages/:id/edit"
                                        element={<EditUIMessage />}
                                    />
                                    <Route
                                        path="/analytics"
                                        element={<Analytics />}
                                    />
                                    <Route
                                        path="/integrations"
                                        element={<NewIntegrations />}
                                    />
                                    <Route
                                        path="/integrations/import"
                                        element={<ImportIntegrations />}
                                    />
                                    <Route
                                        path="/integrations/import/confirm"
                                        element={
                                            <ImportIntegrationsConfirmation />
                                        }
                                    />
                                    <Route
                                        path="/dashboard"
                                        element={<Dashboard />}
                                    />
                                </>
                            )}
                            <Route
                                path="/organizations"
                                element={<Organizations />}
                            />
                            <Route
                                path="/organizations/:id"
                                element={<OrganizationDetails />}
                            />
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Layout>
                </Layout>
                {process.env.NODE_ENV === 'development' && (
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        position="bottom-right"
                    />
                )}
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;

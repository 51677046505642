import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Table, Tag, message } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { AlignType } from 'rc-table/lib/interface';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Collection, notifyError } from '../api';
import { createOrganization, getOrganizations } from '../api/organizations';
import { organizationParameters } from '../atoms';
import Header from '../components/Header';
import SystemTag from '../components/SystemTag';
import { CollectionOrganization, Organization } from '../interfaces';
import { renderDateTime } from '../util/datetime';
import { sortOrder } from '../util/table';

const Organizations = () => {
    const [newModalVisible, setNewModalVisible] = useState(false);

    const queryClient = useQueryClient();
    const organizationsQuery = useQuery('organizations', () =>
        getOrganizations()
    );
    const newOrganizationMutation = useMutation(
        (data: Partial<Organization>) => createOrganization(data),
        {
            onSuccess: (created: Organization) => {
                setNewModalVisible(false);
                message.success('Organisaatio luotu onnistuneesti');
                queryClient.setQueryData(
                    'organizations',
                    (collection: Collection<CollectionOrganization>) => ({
                        ...collection,
                        items: [
                            ...collection.items,
                            {
                                ...created,
                                clients: 0,
                                members: 0,
                                teams: 0,
                            },
                        ],
                    })
                );
            },
            onError: notifyError,
        }
    );

    const [params, setParams] = useRecoilState(organizationParameters);

    const organizationColumns = useMemo(
        () => [
            {
                title: () => (
                    <Space onClick={(e) => e.stopPropagation()} size="middle">
                        Nimi
                        <Input.Search
                            allowClear
                            value={params.name}
                            onChange={(e) =>
                                setParams({ ...params, name: e.target.value })
                            }
                            placeholder="Hae..."
                        />
                    </Space>
                ),
                dataIndex: 'name',
                key: 'name',
                sorter: (
                    a: CollectionOrganization,
                    b: CollectionOrganization
                ) => a.name.localeCompare(b.name),
                sortOrder: sortOrder(params, 'name'),
                render: (
                    name: string,
                    organization: CollectionOrganization
                ) => (
                    <Link className="" to={`/organizations/${organization.id}`}>
                        <Text>{name}</Text>
                    </Link>
                ),
            },
            {
                title: 'Tyyppi',
                dataIndex: 'providerId',
                key: 'type',
                width: 80,
                render: (providerId: number) =>
                    providerId === 1 ? (
                        <Tag>Tavallinen</Tag>
                    ) : (
                        <SystemTag system="ai-inside" />
                    ),
                filters: [
                    { text: 'AI Inside', value: 'ai-inside' },
                    { text: 'Tavallinen', value: 'normal' },
                ],
                filterMultiple: false,
                onFilter: (
                    value: string,
                    organization: CollectionOrganization
                ) =>
                    value === 'normal'
                        ? organization.providerId === 1
                        : organization.providerId !== 1,
                filtered: !!params.type,
                filteredValue: params.type,
            },
            {
                title: 'Y-tunnus',
                dataIndex: 'businessId',
                key: 'businessId',
                render: (businessId: string) => businessId || '-',
            },
            {
                title: 'Hinnoittelu',
                dataIndex: 'billingPlanId',
                key: 'billingPlanId',
                render: (billingPlanId: string) =>
                    billingPlanId ? <Text code>{billingPlanId}</Text> : '-',
            },
            {
                title: 'Jäsenet',
                dataIndex: 'members',
                key: 'members',
                width: 120,
                align: 'center' as AlignType,
                render: (members: any[]) => <Tag color="cyan">{members}</Tag>,
                sorter: (
                    a: CollectionOrganization,
                    b: CollectionOrganization
                ) => a.members - b.members,
                sortOrder: sortOrder(params, 'members'),
            },
            {
                title: 'Asiakkaat',
                dataIndex: 'clients',
                key: 'clients',
                width: 120,
                align: 'center' as AlignType,
                render: (clients: any[]) => <Tag color="green">{clients}</Tag>,
                sorter: (
                    a: CollectionOrganization,
                    b: CollectionOrganization
                ) => a.clients - b.clients,
                sortOrder: sortOrder(params, 'clients'),
            },
            {
                title: 'Tiimit',
                dataIndex: 'teams',
                key: 'teams',
                width: 120,
                align: 'center' as AlignType,
                render: (teams: any[]) => <Tag color="purple">{teams}</Tag>,
                sorter: (
                    a: CollectionOrganization,
                    b: CollectionOrganization
                ) => a.teams - b.teams,
                sortOrder: sortOrder(params, 'teams'),
            },
            {
                title: 'Luotu',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 300,
                render: (createdAt: string) => (
                    <Text type="secondary">{renderDateTime(createdAt)}</Text>
                ),
                sorter: (
                    a: CollectionOrganization,
                    b: CollectionOrganization
                ) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
                sortOrder: sortOrder(params, 'createdAt'),
            },
        ],
        [params, setParams]
    );

    const data = useMemo(
        () =>
            organizationsQuery.data?.items.filter((o) =>
                o.name.toLowerCase().includes(params.name?.toLowerCase() || '')
            ) || [],
        [params.name, organizationsQuery.data]
    );

    const onTableChange = (pagination: any, filters: any, sorter: any) => {
        setParams((params) => ({
            ...params,
            ...filters,
            orderBy: sorter.order && sorter.field,
            order: sorter.order && sorter.order === 'ascend' ? 'asc' : 'desc',
            page: pagination.current === params.page ? 1 : pagination.current,
            limit: pagination.pageSize,
        }));
    };

    return (
        <Content>
            <Header />
            <Space size="large" style={{ margin: '16px 0 24px 0' }}>
                <Title level={2} style={{ margin: 0 }}>
                    Organisaatiot
                </Title>
                <Button
                    onClick={() => setNewModalVisible(true)}
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    Uusi organisaatio
                </Button>
            </Space>
            <Table
                onChange={onTableChange}
                bordered
                showSorterTooltip={false}
                rowKey="id"
                dataSource={data}
                pagination={{
                    current: params.page,
                    pageSize: params.limit,
                }}
                columns={organizationColumns}
                footer={() => `${data.length} tulosta`}
                loading={organizationsQuery.isLoading}
            />
            <Modal
                destroyOnClose
                title="Uusi organisaatio"
                open={newModalVisible}
                onCancel={() => setNewModalVisible(false)}
                footer={null}
            >
                <NewOrganizationForm
                    onSubmit={(data) => newOrganizationMutation.mutate(data)}
                    submitting={newOrganizationMutation.isLoading}
                />
            </Modal>
        </Content>
    );
};

const NewOrganizationForm = (props: {
    onSubmit: (data: Partial<Organization>) => void;
    submitting: boolean;
}) => {
    return (
        <Form
            preserve={false}
            autoComplete="off"
            onFinish={(data) => {
                props.onSubmit(data);
            }}
        >
            <Form.Item label="Nimi" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item>
                <Button
                    loading={props.submitting}
                    style={{ float: 'right' }}
                    type="primary"
                    htmlType="submit"
                >
                    Luo organisaatio
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Organizations;

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Select, Space, Tooltip } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getOrganizations } from '../api/organizations';
import { Client, User } from '../interfaces';
import { notifyError } from '../api';

interface OrganizationSwitcherProps<T> {
    entity?: T;
    entities?: T[];
    updater: (entity: T, orgId: number | null) => void;
}

const OrganizationSwitcher = <T extends Client | User>({
    entity,
    entities = [],
    updater,
}: OrganizationSwitcherProps<T>) => {
    if (entities.length === 0 && entity) entities.push(entity);

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<
        number | undefined
    >(undefined);

    const { data: organizations, isLoading } = useQuery(
        'organizations',
        getOrganizations
    );

    const updateOrganization = useMutation(
        (orgId: number | null) =>
            Promise.all(entities.map((entity) => updater(entity, orgId))),
        {
            onSuccess: (orgId) => {
                if (orgId) {
                    message.success('Lisätty organisaatioon');
                } else {
                    message.info('Poistettu organisaatiosta');
                }
                setSelectedOrganizationId(undefined);
            },
            onError: notifyError,
        }
    );

    if (entities.some((entity) => entity.organizationId !== null)) {
        return (
            <Tooltip title="Poista organisaatiosta">
                <Popconfirm
                    title="Oletko varma?"
                    onConfirm={() => updateOrganization.mutate(null)}
                    okText="Kyllä"
                    cancelText="Ei"
                    placement="bottom"
                >
                    <Button
                        style={{ margin: '4px 0' }}
                        loading={updateOrganization.isLoading}
                        size="small"
                        icon={<CloseOutlined />}
                    />
                </Popconfirm>
            </Tooltip>
        );
    }

    if (!entities.every((entity) => entity.organizationId === null)) {
        return (
            <Popconfirm
                title="Oletko varma?"
                onConfirm={() => updateOrganization.mutate(null)}
                okText="Kyllä"
                cancelText="Ei"
                placement="bottom"
            >
                <Button
                    style={{ margin: '4px 0' }}
                    loading={updateOrganization.isLoading}
                    icon={<CloseOutlined />}
                >
                    Poista organisaatiosta
                </Button>
            </Popconfirm>
        );
    }

    return (
        <Space align="center">
            <Select
                disabled={!entities.length}
                placeholder="Lisää organisaatioon"
                onChange={(value: number) => setSelectedOrganizationId(value)}
                loading={isLoading}
                value={selectedOrganizationId}
                showSearch
                popupMatchSelectWidth={false}
                optionFilterProp="children"
                filterOption={(input: string, option: BaseOptionType) =>
                    option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: 230 }}
                allowClear
            >
                {organizations?.items.map((org) => (
                    <Select.Option key={org.id} value={org.id}>
                        {org.name}
                    </Select.Option>
                ))}
            </Select>
            <Tooltip title="Lisää organisaatioon">
                <Button
                    icon={<CheckOutlined />}
                    loading={updateOrganization.isLoading}
                    disabled={!selectedOrganizationId}
                    onClick={() =>
                        updateOrganization.mutate(selectedOrganizationId!)
                    }
                />
            </Tooltip>
        </Space>
    );
};

export default OrganizationSwitcher;

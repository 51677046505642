import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Descriptions,
    Divider,
    Input,
    List,
    message,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Progress,
    Space,
    Spin,
    Switch,
    Table,
    Tabs,
    Tooltip,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { APIError, no404Retry, notifyError } from '../api';
import {
    createClientAutomation,
    dataDeletionRequest,
    deleteClientAutomation,
    getClientByID,
    reloadTrainingData,
    updateClient,
} from '../api/clients';
import { createMLModel } from '../api/mlModels';
import AddToTeam from '../components/AddToTeam';
import AutomationDetails from '../components/AutomationDetails';
import BackLink from '../components/BackLink';
import ClientCOA from '../components/ClientCOA';
import ClientDimensions from '../components/ClientDimensions';
import EInvoiceAddress from '../components/EInvoiceAddress';
import Header from '../components/Header';
import Identifier from '../components/Identifier';
import MLModelDetails from '../components/MLModelDetails';
import OrganizationSwitcher from '../components/OrganizationSwitcher';
import ServiceTemplate from '../components/ServiceTemplate';
import SystemTag from '../components/SystemTag';
import TagEditor from '../components/TagEditor';
import TeamList from '../components/TeamList';
import TrainingRequestForm from '../forms/TrainingRequestForm';
import { Client, Tags } from '../interfaces';
import { MLModel, MLModelTrainingRequest } from '../interfaces/mlModel';
import { renderDate, renderDateTime } from '../util/datetime';
import ErrorPage from './ErrorPage';
import { deleteInvoice, getInvoices } from '../api/invoices';
import ClientSettings from '../components/ClientSettings';
import { v4 as uuid } from 'uuid';

/**
 * Following will be shown in descriptions table:
 * - Name
 * - Business ID
 * - Contact email
 * - Country
 * - Domain name
 * - E-Invoice address
 * - filters
 * - main line of business / tol
 * - fiscal period treshold
 * - service template
 * - organization
 * - teams
 * - status
 * - created
 * - updated
 * - start date for fetching invoices
 * - invoices last fetched at
 * - system
 * - tags
 * - integration key
 * - id
 * - firebase key
 */

const { TabPane } = Tabs;

const ClientDetails = () => {
    const id = parseInt(useParams().id || '0');

    const {
        data: client,
        isLoading,
        isError,
        error,
    } = useQuery(['client', id], () => getClientByID(id), {
        retry: no404Retry,
    });

    const queryClient = useQueryClient();

    const clientMutation = useMutation(
        (updates: Partial<Client>) => updateClient(id, updates),
        {
            onSuccess: (updated: Client, variables) => {
                message.success('Asiakkaan tiedot päivitetty!');
                queryClient.setQueryData(['client', id], updated);

                if (variables.teams) {
                    queryClient.invalidateQueries('teams');
                }
            },
            onError: notifyError,
        }
    );

    const clientUpdater = (client: Client, organizationId: number | null) =>
        updateClient(client.id, { organizationId }).then((client) => {
            queryClient.setQueryData(['client', client.id], client);
            queryClient.invalidateQueries('clients');
        });

    const reloadTrainingDataMutation = useMutation(
        () => reloadTrainingData(id),
        {
            onSuccess: () => {
                message.success('Koulutusaineiston uudelleenlataus tilattu!');
            },
            onError: notifyError,
        }
    );

    const handleCreateAutomation = useMutation(
        () =>
            createClientAutomation(client!.id, {
                type: 'GROWLITHE',
                name: 'Älykäs Automaatio',
                avatarURL:
                    'https://fabricai-public.s3.eu-central-1.amazonaws.com/automation_avatar/avatar_0.png',
                key: uuid(),
            }),
        {
            onSuccess: () => {
                message.success('Automaatio luotu!');
                queryClient.invalidateQueries(['client', client!.id]);
            },
            onError: notifyError,
        }
    );

    const handleDeleteAutomation = useMutation(
        (id: number) => deleteClientAutomation(client!.id, id),
        {
            onSuccess: () => {
                message.success('Automaatio poistettu!');
                queryClient.invalidateQueries(['client', client!.id]);
            },
            onError: notifyError,
        }
    );

    const [searchParams, setSearchParams] = useSearchParams();

    const [nameEditorState, setNameEditorState] = useState<{
        visible: boolean;
        name: string;
    }>({ visible: false, name: '' });
    const [displayNameEditorState, setDisplayNameEditorState] = useState<{
        visible: boolean;
        displayName: string;
    }>({ visible: false, displayName: '' });

    if (isLoading) return <Spin size="large" />;

    if (isError)
        return (
            <ErrorPage
                title={`Virhe haettaessa asiakasta ${id}`}
                error={error}
                backTo="clients"
            />
        );

    /**
     * Sort the models by their status starting from DEPLOYED -> FAILED -> ...rest in id order
     */
    const sortedMlModels = client?.mlModels?.sort((a, b) => {
        if (a.status === 'DEPLOYED') return -1;
        if (b.status === 'DEPLOYED') return 1;

        if (a.status === 'FAILED') return -1;
        if (b.status === 'FAILED') return 1;
        if (a.modelId && b.modelId) {
            return +b.modelId - +a.modelId;
        }
        return a.status.localeCompare(b.status);
    });

    return (
        <Content>
            <Header resolver={() => client?.name} />
            <Space style={{ marginBottom: 24 }} align="center" size="middle">
                <BackLink to="/clients" />
                <Title level={2} style={{ margin: 0 }}>
                    {client?.name}
                </Title>
                <Popover
                    title="Muokkaa asiakkaan nimeä"
                    trigger="click"
                    open={nameEditorState.visible}
                    onOpenChange={(visible) =>
                        setNameEditorState({ visible, name: client!.name })
                    }
                    placement="bottom"
                    content={
                        <>
                            <Input
                                disabled={clientMutation.isLoading}
                                value={nameEditorState.name}
                                onChange={(e) =>
                                    setNameEditorState({
                                        visible: true,
                                        name: e.target.value,
                                    })
                                }
                            />
                            <Button
                                type="primary"
                                loading={clientMutation.isLoading}
                                onClick={() =>
                                    clientMutation.mutate(
                                        { name: nameEditorState.name },
                                        {
                                            onSuccess: () => {
                                                setNameEditorState({
                                                    visible: false,
                                                    name: '',
                                                });
                                            },
                                        }
                                    )
                                }
                                style={{ marginTop: 8 }}
                            >
                                Tallenna
                            </Button>
                        </>
                    }
                >
                    <Button type="text" icon={<EditOutlined />}></Button>
                </Popover>
            </Space>

            <Descriptions bordered column={2} size="middle">
                <Descriptions.Item span={2} label="Näyttönimi UI:ssa">
                    <Space>
                        {displayNameEditorState.visible ? (
                            <>
                                <Input
                                    style={{ marginLeft: -8, width: 250 }}
                                    size="small"
                                    value={displayNameEditorState.displayName}
                                    onChange={(e) =>
                                        setDisplayNameEditorState({
                                            visible: true,
                                            displayName: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => {
                                        let tags = { ...client?.tags };

                                        if (
                                            displayNameEditorState.displayName ===
                                                '' ||
                                            displayNameEditorState.displayName ===
                                                client?.name
                                        ) {
                                            delete tags.displayName;
                                        } else {
                                            tags.displayName =
                                                displayNameEditorState.displayName;
                                        }

                                        clientMutation.mutate(
                                            { tags },
                                            {
                                                onSuccess: () => {
                                                    setDisplayNameEditorState({
                                                        visible: false,
                                                        displayName: '',
                                                    });
                                                },
                                            }
                                        );
                                    }}
                                    icon={<CheckOutlined />}
                                />
                            </>
                        ) : (
                            client?.displayName
                        )}
                        <Button
                            size="small"
                            type="text"
                            style={{ marginRight: -8 }}
                            icon={
                                displayNameEditorState.visible ? (
                                    <CloseOutlined />
                                ) : (
                                    <EditOutlined />
                                )
                            }
                            onClick={() =>
                                setDisplayNameEditorState({
                                    visible: !displayNameEditorState.visible,
                                    displayName: client!.displayName,
                                })
                            }
                        />
                    </Space>
                </Descriptions.Item>

                <Descriptions.Item label="Järjestelmä">
                    <SystemTag system={client?.accountingSystem} />
                </Descriptions.Item>
                <Descriptions.Item label="Y-tunnus / ALV-numero">
                    {client?.businessId || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Aktiivinen">
                    <Switch
                        checked={client?.status === 'ACTIVE'}
                        onChange={(checked) =>
                            clientMutation.mutate({
                                status: checked ? 'ACTIVE' : 'INACTIVE',
                            })
                        }
                        loading={
                            clientMutation.isLoading &&
                            !!clientMutation.variables?.status
                        }
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Sähköposti">
                    {client?.contactEmail || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Organisaatio">
                    <Space align="center" style={{ width: 300 }}>
                        {client?.organization?.name || (
                            <Text type="secondary">-</Text>
                        )}
                        <Divider type="vertical" />
                        <OrganizationSwitcher
                            entity={client!!}
                            updater={clientUpdater}
                        />
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Verkkotunnus">
                    {client?.domain || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Palvelumalli">
                    <ServiceTemplate
                        loading={
                            clientMutation.isLoading &&
                            !!clientMutation.variables?.serviceTemplate
                        }
                        serviceTemplate={client?.serviceTemplate}
                        onChange={(serviceTemplate) =>
                            clientMutation.mutate({ serviceTemplate })
                        }
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Maakoodi">
                    {client?.country || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Aloituspäivämäärä">
                    <DateSwitcher
                        loading={
                            clientMutation.isLoading &&
                            !!clientMutation.variables
                                ?.startDateForFetchingInvoices
                        }
                        value={client?.startDateForFetchingInvoices || ''}
                        onChange={(startDateForFetchingInvoices) =>
                            clientMutation.mutate({
                                startDateForFetchingInvoices,
                            })
                        }
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Päätoimiala / TOL">
                    {(client?.mainLineOfBusiness || '-') +
                        ' / ' +
                        (client?.tol || '-')}
                </Descriptions.Item>
                <Descriptions.Item label="Laskut haettu viimeksi">
                    {renderDateTime(client?.invoicesLastFetched)}
                    <Divider type="vertical" style={{ margin: '0 2em' }} />
                    <Popconfirm
                        title="Haluatko varmasti nollata hakupäivämäärän?"
                        onConfirm={() =>
                            clientMutation.mutate({ invoicesLastFetched: null })
                        }
                    >
                        <Button
                            size="small"
                            disabled={!client?.invoicesLastFetched}
                        >
                            Nollaa
                        </Button>
                    </Popconfirm>
                </Descriptions.Item>
                <Descriptions.Item label="Tilikauden raja">
                    {renderDate(client?.fiscalPeriodTreshold)}
                </Descriptions.Item>
                <Descriptions.Item label="Integraatio-avain">
                    <Identifier>{client?.integrationKey}</Identifier>
                    <Divider type="vertical" style={{ margin: '0 16px' }} />
                    <Popconfirm
                        title="Haluatko varmasti pakottaa koulutusaineiston uudelleenlatauksen?"
                        onConfirm={() => reloadTrainingDataMutation.mutate()}
                    >
                        <Button size="small">
                            Tilaa koulutusaineiston haku
                        </Button>
                    </Popconfirm>
                </Descriptions.Item>
                <Descriptions.Item label="Luotu">
                    {renderDateTime(client?.createdAt)}
                </Descriptions.Item>
                <Descriptions.Item label="ID / Firebasen ID">
                    <Identifier code={false}>{client?.id}</Identifier>
                    <Divider type="vertical" style={{ margin: '0 16px' }} />
                    <Identifier>{client?.key}</Identifier>
                </Descriptions.Item>
                <Descriptions.Item label="Päivitetty">
                    {renderDateTime(client?.updatedAt)}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Verkkolaskuosoite">
                    <EInvoiceAddress address={client?.eInvoiceAddress || {}} />
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Asetukset">
                    {client && (
                        <ClientSettings
                            client={client}
                            loading={
                                clientMutation.isLoading &&
                                (!!clientMutation.variables?.filters ||
                                    !!clientMutation.variables
                                        ?.defaultsAndSettings)
                            }
                            onChange={(client) => clientMutation.mutate(client)}
                        />
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Tagit">
                    <TagEditor
                        inputStyle={{ width: 200 }}
                        color={(tag) =>
                            tag.startsWith('integration_')
                                ? 'geekblue'
                                : undefined
                        }
                        tags={Object.entries(client?.tags || {}).map(
                            ([key, value]) => `${key}=${value}`
                        )}
                        onChange={(tags) => {
                            const tagsMap = tags.reduce((acc, tag) => {
                                const [key, value] = tag.split('=');
                                acc[key!] = value!;
                                return acc;
                            }, {} as Tags);
                            clientMutation.mutate({ tags: tagsMap });
                        }}
                        validate={(tag: string) => tag.split('=').length === 2}
                        loading={
                            clientMutation.isLoading &&
                            !!clientMutation.variables?.tags
                        }
                    />
                </Descriptions.Item>
            </Descriptions>

            <Tabs
                defaultActiveKey={searchParams.get('tab') || undefined}
                style={{ marginTop: 32 }}
                onChange={(key) => {
                    searchParams.set('tab', key);
                    setSearchParams(searchParams);
                }}
            >
                <TabPane tab="Ennustusmallit" key="mlModels">
                    <NewTrainingRequest clientId={id} />
                    <List
                        style={{ marginTop: 16 }}
                        grid={{
                            gutter: 32,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 1,
                            xl: 2,
                            xxl: 2,
                        }}
                        dataSource={sortedMlModels}
                        renderItem={(mlModel) => (
                            <List.Item key={mlModel.trainingId}>
                                <MLModelDetails model={mlModel} clientId={id} />
                            </List.Item>
                        )}
                    />
                </TabPane>
                <TabPane tab="Automaatiot" key="automations">
                    <Button
                        onClick={() => handleCreateAutomation.mutate()}
                        loading={handleCreateAutomation.isLoading}
                    >
                        Lisää uusi Itseohjautuva Automaatio
                    </Button>
                    <List
                        style={{ marginTop: 16 }}
                        grid={{
                            gutter: 32,
                            column: 1,
                        }}
                        dataSource={client?.automations.approval || undefined}
                        renderItem={(automation) => (
                            <List.Item key={automation.id}>
                                <AutomationDetails
                                    deletable={
                                        (
                                            client?.automations.approval || []
                                        ).filter((a) => a.type === 'GROWLITHE')
                                            .length > 1 &&
                                        automation.type === 'GROWLITHE'
                                    }
                                    onDelete={handleDeleteAutomation.mutate}
                                    automation={automation}
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>
                <TabPane
                    disabled={!client?.organizationId}
                    tab="Tiimit"
                    key="teams"
                >
                    <TeamList
                        teams={client?.teams}
                        onRemove={(team) =>
                            clientMutation.mutate({
                                teams: client!.teams!.filter(
                                    (t) => t.id !== team.id
                                ),
                            })
                        }
                    />
                    <AddToTeam
                        style={{ marginTop: 32 }}
                        submitting={
                            clientMutation.isLoading &&
                            !!clientMutation.variables?.teams
                        }
                        onSubmit={(team) =>
                            clientMutation.mutate({
                                teams: [...(client?.teams || []), team],
                            })
                        }
                        organizationId={client?.organizationId || 0}
                        blacklist={client?.teams?.map((team) => team.id)}
                    />
                </TabPane>
                <TabPane tab="Historia" key="history">
                    <Space
                        style={{ width: '100%' }}
                        size="large"
                        direction="vertical"
                    >
                        <Table
                            size="small"
                            rowKey="endTime"
                            bordered
                            pagination={false}
                            columns={[
                                {
                                    title: 'Tila',
                                    dataIndex: 'status',
                                    key: 'status',
                                },
                                {
                                    title: 'Päättyi',
                                    dataIndex: 'endTime',
                                    key: 'endTime',
                                    render: (endTime: string) =>
                                        renderDateTime(endTime),
                                },
                            ]}
                            dataSource={client?.statusHistory}
                        />
                        <Table
                            size="small"
                            rowKey="endTime"
                            bordered
                            pagination={false}
                            columns={[
                                {
                                    title: 'Palvelumalli',
                                    dataIndex: 'serviceTemplate',
                                    key: 'serviceTemplate',
                                },
                                {
                                    title: 'Päättyi',
                                    dataIndex: 'endTime',
                                    key: 'endTime',
                                    render: (endTime: string) =>
                                        renderDateTime(endTime),
                                },
                            ]}
                            dataSource={client?.serviceTemplateHistory}
                        />
                        <Table
                            size="small"
                            rowKey="endTime"
                            bordered
                            pagination={false}
                            columns={[
                                {
                                    title: 'Tilikauden raja',
                                    dataIndex: 'fiscalPeriodTreshold',
                                    key: 'fiscalPeriodTreshold',
                                    render: (fiscalPeriodTreshold: string) =>
                                        renderDate(fiscalPeriodTreshold),
                                },
                                {
                                    title: 'Päättyi',
                                    dataIndex: 'endTime',
                                    key: 'endTime',
                                    render: (endTime: string) =>
                                        renderDateTime(endTime),
                                },
                            ]}
                            dataSource={client?.fiscalPeriodTresholdHistory}
                        />
                    </Space>
                </TabPane>
                <TabPane tab="Tilikartta" key="coa">
                    <ClientCOA clientId={client!.id} />
                </TabPane>
                <TabPane tab="Dimensiot" key="dimensions">
                    <ClientDimensions clientId={client!.id} />
                </TabPane>
                <TabPane tab="Poista Tietoja" key="deleteInvoices">
                    <Space direction="vertical">
                        <InvoiceDelete clientId={client!.id} />
                        <DeleteAllClientData clientId={client!.id} />
                    </Space>
                </TabPane>
            </Tabs>
        </Content>
    );
};

export default ClientDetails;

interface DateSwitcherProps {
    loading: boolean;
    value: string;
    onChange: (value: string) => void;
}
const DateSwitcher = (props: DateSwitcherProps) => {
    const { loading, onChange } = props;

    const initial = dayjs(props.value);
    const [value, setValue] = useState(initial);

    useEffect(() => {
        setValue(initial);
    }, [props.value]);

    return (
        <Space style={{ marginLeft: -12 }}>
            <DatePicker
                allowClear={false}
                variant={'borderless'}
                value={value}
                onChange={(value) => setValue(value!)}
                disabled={loading}
                format="DD.MM.YYYY"
            />
            {!value?.isSame(initial) && (
                <>
                    <Button
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={() => setValue(initial)}
                    />
                    <Button
                        size="small"
                        icon={<CheckOutlined />}
                        loading={loading}
                        onClick={() => onChange(value.toDate().toDateString())}
                    />
                </>
            )}
            <Divider type="vertical" />
            <Tooltip title="Asettaa aloituspäivämäärän 1 viikon nykyhetkestä taaksepäin">
                <Button
                    style={{ marginLeft: 12 }}
                    size="small"
                    onClick={() =>
                        onChange(
                            dayjs().subtract(1, 'week').toDate().toDateString()
                        )
                    }
                >
                    Pakota laskujen haku
                </Button>
            </Tooltip>
        </Space>
    );
};

const DeleteAllClientData = ({ clientId }: { clientId: number }) => {
    const clientMutation = useMutation(() => dataDeletionRequest(clientId), {
        onSuccess: () => {
            message.success('Kaikki asiakkaan tiedot poistettu!');
        },
        onError: notifyError,
    });

    const showConfirmation = () => {
        Modal.confirm({
            title: 'Vahvista poisto',
            content:
                'Haluatko varmasti poistaa kaikki asiakkaan tiedot? Tätä toimintoa ei voi peruuttaa.',
            okText: 'Kyllä',
            okType: 'danger',
            cancelText: 'Peruuta',
            onOk() {
                clientMutation.mutate();
            },
        });
    };

    return (
        <Space
            direction="vertical"
            size="large"
            style={{
                marginTop: 16,
                padding: '16px',
                border: '1px solid #E57A7AFF',
                borderRadius: '4px',
            }}
        >
            <Text style={{ fontSize: '16px', fontWeight: 500 }}>
                Allaoleva nappi poistaa KAIKEN asiakkaan datan
            </Text>
            <Button
                type="primary"
                danger
                style={{ alignSelf: 'start' }}
                size="middle"
                onClick={showConfirmation}
            >
                Poista KAIKKI asiakkaan data
            </Button>
        </Space>
    );
};

const InvoiceDelete = (props: { clientId: number }) => {
    const { clientId } = props;

    const initial = dayjs();
    const [value, setValue] = useState(initial);

    const [invoicesToDelete, setInvoicesToDelete] = useState<number[]>([]);

    const [deleted, setDeleted] = useState(0);

    const [status, setStatus] = useState<
        'idle' | 'getInvoices' | 'deleteInvoices' | 'success' | 'error'
    >('idle');

    const fetchInvoiceIds = async (page = 1) => {
        setStatus('getInvoices');
        const invoices = await getInvoices({
            clientId,
            page,
            status: 'PENDING',
            createdAt: 'lt:' + value.toDate().toISOString(),
        });

        if (invoices.length === 0) {
            setStatus('deleteInvoices');
            return;
        }

        setInvoicesToDelete((old) => [...old, ...invoices.map((i) => i.id)]);
        fetchInvoiceIds(page + 1);
    };

    const deleteInvoices = async (invoicesToDelete: number[]) => {
        for (const invoiceId of invoicesToDelete) {
            await deleteInvoice(invoiceId);
            setDeleted((old) => old + 1);
        }
        setStatus('success');
        return;
    };

    return (
        <Space
            direction="vertical"
            size="large"
            style={{
                marginTop: 16,
                padding: '16px',
                border: '1px solid #808080',
                borderRadius: '4px',
            }}
        >
            {status === 'idle' && (
                <Space>
                    <DatePicker
                        allowClear={false}
                        bordered={false}
                        value={value}
                        onChange={(value) => setValue(value!)}
                        format="DD.MM.YYYY"
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Oletko AIVAN varma?"
                        onConfirm={() => fetchInvoiceIds()}
                        okText="Kyllä"
                        cancelText="Ei"
                    >
                        <Button danger style={{ marginLeft: 12 }} size="small">
                            Poista kaikki PENDING-laskut, jotka on luotu ENNEN{' '}
                            {value.toDate().toLocaleDateString()}
                        </Button>
                    </Popconfirm>
                </Space>
            )}
            {status === 'getInvoices' && (
                <Space>
                    Haetaan laskujen tietoja
                    <Spin />
                    {invoicesToDelete.length}
                </Space>
            )}
            {status === 'deleteInvoices' && (
                <Space direction="vertical">
                    Poistetaan {invoicesToDelete.length} laskua
                    {deleted === 0 ? (
                        <Button
                            danger
                            style={{ marginLeft: 12 }}
                            size="small"
                            onClick={() => deleteInvoices(invoicesToDelete)}
                        >
                            Poista
                        </Button>
                    ) : (
                        <div style={{ width: 300 }}>
                            <Progress
                                percent={Math.round(
                                    (100 * deleted) / invoicesToDelete.length
                                )}
                            />
                        </div>
                    )}
                </Space>
            )}
            {status === 'success' && (
                <Space>Poistettiin {invoicesToDelete.length} laskua</Space>
            )}
        </Space>
    );
};

const NewTrainingRequest = ({ clientId }: { clientId: number }) => {
    const [visible, setVisible] = useState(false);

    const queryClient = useQueryClient();

    const trainignRequestMutation = useMutation(
        (request: MLModelTrainingRequest) => createMLModel(clientId, request),
        {
            onSuccess: (newModel: MLModel) => {
                setVisible(false);
                message.success('Ennustusmallin luominen onnistui');
                queryClient.setQueryData(
                    ['client', clientId],
                    (client: Client) => ({
                        ...client,
                        mlModels: [...(client.mlModels || []), newModel],
                    })
                );
            },
            onError: (error: APIError) => {
                notification.error({
                    message: error.message,
                    description: error.response?.data.error,
                });
            },
        }
    );

    return (
        <>
            <Modal
                title="Uusi ennustusmalli"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={600}
            >
                <TrainingRequestForm
                    clientId={clientId}
                    loading={trainignRequestMutation.isLoading}
                    onSubmit={trainignRequestMutation.mutate}
                />
            </Modal>
            <Button
                type="primary"
                onClick={() => setVisible(true)}
                icon={<PlusOutlined />}
            >
                Uusi malli
            </Button>
        </>
    );
};

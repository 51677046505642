import {
    ApartmentOutlined,
    ApiOutlined,
    AppstoreOutlined,
    FileSearchOutlined,
    LineChartOutlined,
    NotificationOutlined,
    ShopOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib';
import Sider from 'antd/lib/layout/Sider';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User } from '../interfaces';

const logo = new URL('../logo.svg', import.meta.url).toString();

/**
 * clients - Asiakkaat
 * uiMessages - UI-Tiedotteet
 * analytics - Analytiikka
 * organizations - Organisaatiot
 * users - Käyttäjät
 * dashboard - Yleisnäkymä
 * integrations - Kytkennät
 */
function Navigation(props: {user: User}) {
    const { user } = props;
    const location = useLocation();
    const parts = location.pathname.split('/').filter((i) => i);
    const [collapsed, setCollapsed] = useState(
        localStorage.getItem('collapsed') === 'true'
    );

    const onCollapse = () => {
        setCollapsed(!collapsed);
        localStorage.setItem('collapsed', (!collapsed).toString());
    };

    const isFabricaiBilling = user?.role === 'FABRICAI_BILLING';

    const items: MenuProps['items'] = isFabricaiBilling
        ? [
              {
                  label: 'Organisaatiot',
                  key: 'organizations',
                  icon: <ApartmentOutlined />,
                  to: '/organizations',
              },
          ].map((item) => ({
              ...item,
              label: <Link to={item.to}>{item.label}</Link>,
          }))
        : [
              {
                  label: 'Yleisnäkymä',
                  key: 'dashboard',
                  icon: <AppstoreOutlined />,
                  to: '/dashboard',
              },
              {
                  label: 'Asiakkaat',
                  key: 'clients',
                  icon: <ShopOutlined />,
                  to: '/clients',
              },
              {
                  label: 'Organisaatiot',
                  key: 'organizations',
                  icon: <ApartmentOutlined />,
                  to: '/organizations',
              },
              {
                  label: 'Käyttäjät',
                  key: 'users',
                  icon: <TeamOutlined />,
                  to: '/users',
              },
              {
                  label: 'UI-tiedotteet',
                  key: 'uiMessages',
                  icon: <NotificationOutlined />,
                  to: '/uiMessages',
              },
              {
                  label: 'Analytiikka',
                  key: 'analytics',
                  icon: <LineChartOutlined />,
                  to: '/analytics',
              },
              {
                  label: 'Kytkennät',
                  key: 'integrations',
                  icon: <ApiOutlined />,
                  to: '/integrations',
              },
              {
                  label: 'Käsittelyhistoria',
                  key: 'invoiceQuery',
                  icon: <FileSearchOutlined />,
                  to: '/invoiceQuery',
              },
          ].map((item) => ({
              ...item,
              label: <Link to={item.to}>{item.label}</Link>,
          }));

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div
                style={{
                    margin: 24,
                    marginBottom: 32,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img
                    style={{
                        width: 32,
                        marginRight: 16,
                    }}
                    src={logo}
                    alt="logo"
                />
                <h2
                    style={{
                        color: 'white',
                        marginBottom: 0,
                        opacity: collapsed ? 0 : 1,
                        transition: 'opacity 0.3s',
                        marginTop: 0,
                    }}
                >
                    Admin
                </h2>
            </div>
            <Menu
                inlineCollapsed={collapsed}
                theme="dark"
                mode="inline"
                selectedKeys={parts.length && parts[0] ? [parts[0]] : []}
                items={items}
            />
        </Sider>
    );
}

export default Navigation;

import {
    DownOutlined,
    LogoutOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { useQuery } from "react-query";
import { no404Retry } from "../api";
import { getUserByUID } from "../api/users";
import { auth } from "../util/firebase";
import Breadcrumbs, { SnippetResolver } from "./Breadcrumbs";

const menuItems: MenuProps["items"] = [
    {
        key: "1",
        label: (
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    auth.signOut();
                }}
            >
                Kirjaudu ulos
            </a>
        ),
        icon: <LogoutOutlined />,
    },
];

function Header(props: { resolver?: SnippetResolver }) {
    const { data: user, isError } = useQuery(
        ["user", auth.currentUser?.uid],
        () => getUserByUID(auth.currentUser!.uid),
        {
            enabled: !!auth.currentUser,
            retry: no404Retry,
        }
    );

    const initials =
        user?.name
            ?.split(" ")
            .map((i) => i[0])
            .join("")
            .toUpperCase() ?? "";

    return (
        <div
            style={{ marginBottom: 16, display: "flex", alignItems: "center" }}
        >
            <Breadcrumbs resolver={props.resolver} />
            <div style={{ flex: 1 }}></div>

            <Dropdown menu={{ items: menuItems }}>
                <a
                    onClick={(e) => e.preventDefault()}
                    href="#"
                    className="ant-dropdown-link"
                >
                    <Space>
                        {user?.name} <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
            <Avatar style={{ marginLeft: 16, background: "rgb(245, 56, 85)" }}>
                {isError ? "!" : initials}
            </Avatar>
        </div>
    );
}

export default Header;

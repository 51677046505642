import { z } from 'zod';

export interface CollectionMember {
    id: number;
    key?: string;
    name: string;
}

export interface OrganizationClient extends CollectionMember {
    accountingSystem: string;
    progressiveAutomationEnabled: boolean;
}

export type TeamClient = CollectionMember;

export type UserTeam = CollectionMember;

export type UserOrganization = CollectionMember;

export type UserClient = CollectionMember;

export interface Member {
    email: string;
    name: string;
    role: string;
    uid: string;
}

export interface Organization {
    clients: OrganizationClient[];
    createdAt: string;
    id: number;
    key?: string;
    providerId: number;
    integrations: string[];
    members: Member[];
    name: string;
    owner: string;
    teams: number[];
    tags: { [key: string]: string };
    updatedAt: string;
    businessId: string | null;
    billingPlanId: string | null;
    billingEmail: string | null;
    billingReference: string | null;
    country: string | null;
    city: string | null;
    streetAddress: string | null;
    zipCode: string | null;
    einvoiceAddress: string | null;
    einvoiceOperator: string | null;
}

export interface CollectionOrganization {
    id: number;
    name: string;
    allowCrossUseOfMLModels: boolean;
    clients: number;
    members: number;
    teams: number;
    providerId: number;
    tags: { [key: string]: string };
    createdAt: string;
    updatedAt: string;
}

export interface TeamInput {
    organizationId: number;
    name: string;
    clients: TeamClient[];
    members: Member[];
    tags: { [key: string]: string };
}

export interface Team extends TeamInput {
    readonly id: number;
    readonly integrations: string[];
    readonly createdAt: string;
    readonly updatedAt: string;
}

export interface LocaleStrings {
    [lang: string]: string;
}

export interface UIMessageInput {
    active: boolean;
    description: LocaleStrings;
    systems: string[];
    title: LocaleStrings;
    type: 'info' | 'warning' | 'error';
}

export interface UIMessage extends UIMessageInput {
    readonly id: number;
    readonly createdAt: string;
    readonly updatedAt: string;
}

export interface Tags {
    [key: string]: string;
}

export interface CollectionUser {
    uid: string;
    name: string;
    email: string;
    organizationId: number | null;
    organization?: UserOrganization;
    role?: string | null;
    tags?: Tags;
    showDimensionWarnings: boolean;
    dontShowHighlightings: boolean;
    tooltips: boolean;
    tutorialCompleted: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface User extends CollectionUser {
    completedInvoices: number;
    clients?: string[];
    clientDetails?: UserClient[] | null;
    favorites?: number[];
    teams?: number[];
    // Only present when creating user
    readonly mailchimpId?: string;
}

export interface Integration {
    businessId?: string;
    integrationKey: string;
    name: string;
    accountingOfficeName: string;
    accountingOfficeId: string;
    system: string;
    timestamp: string;
    addedBy: string;
    organizationId: number;
}

export interface ClientInput extends Integration {
    organization?: string;
    team?: string;
    domain?: string;
    mainLineOfBusiness?: string;
    tol?: string;
    serviceTemplate?: string;
    fetchOnlyAccounts?: string;
    dimensions?: boolean;
    startDateForFetchingInvoices?: string;
    activateProgressiveAutomation?: boolean;
    [key: string]: any;
}

export interface UserAction<T, MT> {
    id: number;
    userId: string;
    action: T;
    metadata: MT;
    createdAt: Date;
    userName: string;
}

export interface Change {
    to: string;
    from: string;
    path: string[];
    type: string;
}

export type UpdateAutomationAction = UserAction<
    'update_automation',
    {
        changes: Change[];
        clientId: number;
        clientName: string;
        automationId: number;
    }
>;

export interface Report {
    fileName: string;
    lastModified: string;
    presignedURL: string;
}

export interface OldPeriod {
    id: number;
    teamId: number;
    tag: string;
    organizationId: number;
    period: string;
}

export interface PendingReport {
    teamId: number;
    tag: string;
    month: string;
    startedAt: string;
    error?: string;
}

export interface Invoice {
    id: number;
    clientId: number;
    invoiceNumber: string;
    invoiceId: string;
    updatedAt: string;
}

export const billingPlan = z.object({
    createdAt: z.string().readonly().optional(),
    description: z.string(),
    id: z.string(),
    isActive: z.boolean(),
    updatedAt: z.string().readonly().optional(),
});

export type BillingPlan = z.infer<typeof billingPlan>;

export * from './client';
export * from './mlModel';

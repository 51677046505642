"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.commonLocale = void 0;
var commonLocale = exports.commonLocale = {
  yearFormat: 'YYYY',
  dayFormat: 'D',
  cellMeridiemFormat: 'A',
  monthBeforeYear: true
};
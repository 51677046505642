import { Button, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getOrganizations } from '../api/organizations';
import { getTeams } from '../api/teams';
import { CreateUserInput } from '../api/users';
import { roles } from '../util/constants';

const UserForm = (props: {
    onSubmit: (input: CreateUserInput) => void;
    submitting: boolean;
}) => {
    const { data: organizations, isLoading } = useQuery('organizations', () =>
        getOrganizations()
    );
    const { data: teams, isLoading: isLoadingTeams } = useQuery('teams', () =>
        getTeams().then((c) => c.items)
    );
    const [organizationSelected, setOrganizationSelected] = useState<
        number | null
    >(null);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ role: 'ORGANIZATION_USER', teamId: null });
    }, [organizationSelected]);

    return (
        <Form
            preserve={false}
            onFinish={props.onSubmit}
            autoComplete="off"
            form={form}
            labelCol={{ span: 7 }}
            initialValues={{ createMailchimpContact: true }}
        >
            <Form.Item label="Nimi" name="name">
                <Input required />
            </Form.Item>
            <Form.Item label="Sähköposti" name="email">
                <Input autoComplete="new-password" type="email" required />
            </Form.Item>
            <Form.Item label="Organisaatio" name="organizationId">
                <Select
                    placeholder="Valitse organisaatio"
                    loading={isLoading}
                    allowClear
                    onChange={(org) => setOrganizationSelected(org)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {organizations?.items.map((organization) => (
                        <Select.Option
                            key={organization.id}
                            value={organization.id}
                        >
                            {organization.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Tiimi" name="teamId">
                <Select
                    disabled={!organizationSelected}
                    placeholder="Valitse tiimi"
                    loading={isLoadingTeams}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {teams
                        ?.filter(
                            (team) =>
                                team.organizationId === organizationSelected
                        )
                        .map((team) => (
                            <Select.Option key={team.id} value={team.id}>
                                {team.name}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="Rooli"
                name="role"
                rules={[{ required: true, message: 'Valitse rooli' }]}
            >
                <Select placeholder="Valitse rooli">
                    {roles.slice(0, 4).map((role) => (
                        <Select.Option key={role.value} value={role.value}>
                            {role.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Salasana" name="password">
                <Input.Password autoComplete="new-password" required />
            </Form.Item>
            <Form.Item
                dependencies={['password']}
                label="Vahvista salasana"
                name="confirmPassword"
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Salasanat eivät täsmää');
                        },
                    }),
                ]}
            >
                <Input.Password required />
            </Form.Item>
            <Form.Item
                valuePropName="checked"
                name="createMailchimpContact"
                label="Mailchimp-kontakti"
            >
                <Input type="checkbox" />
            </Form.Item>
            <Form.Item>
                <Button
                    loading={props.submitting}
                    style={{ float: 'right' }}
                    type="primary"
                    htmlType="submit"
                >
                    Tallenna
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UserForm;

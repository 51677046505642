import { Button, DatePicker, Select, Table } from "antd";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import { find, findLast, head } from "ramda";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notifyError } from "../api";
import {
  BillingPlanRecord,
  BillingPlanRecordInput,
  createBillingPlanRecord,
  deleteBillingPlanRecord,
  getBillingPlans,
  getOrganizationBillingPlanRecords,
  updateBillingPlanRecord,
} from "../api/billingPlans";

const BillingPlanRecordTable = (props: { organizationId: number }) => {
  const { organizationId } = props;

  const billingPlansQuery = useQuery("billingPlans", () =>
    getBillingPlans().then((c) => c.items)
  );

  const billingPlanRecordQuery = useQuery(
    ["billingPlanRecords", organizationId],
    () =>
      getOrganizationBillingPlanRecords(organizationId).then((r) => r.items),
    {
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  const createBillingPlanRecordMutation = useMutation(
    (data: BillingPlanRecordInput) =>
      createBillingPlanRecord(organizationId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["billingPlanRecords", organizationId]);
        queryClient.invalidateQueries(["organization", organizationId]);
        setEditorValues({});
      },
      onError: notifyError,
    }
  );

  const updateBillingPlanRecordMutation = useMutation(
    ({ id, ...data }: BillingPlanRecordInput & { id: number }) =>
      updateBillingPlanRecord(organizationId, id, data),
    {
      onSuccess: (updated: BillingPlanRecord) => {
        queryClient.setQueryData(
          ["billingPlanRecords", organizationId],
          (records: BillingPlanRecord[]) =>
            records.map((r) => (r.id === updated.id ? updated : r))
        );
        queryClient.invalidateQueries(["organization", organizationId]);
        setEditorValues({});
      },
      onError: notifyError,
    }
  );

  const deleteBillingPlanRecordMutation = useMutation(
    (id: number) => deleteBillingPlanRecord(organizationId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["billingPlanRecords", organizationId]);
        queryClient.invalidateQueries(["organization", organizationId]);
        setEditorValues({});
      },
      onError: notifyError,
    }
  );

  const [editorValues, setEditorValues] = React.useState<
    Partial<BillingPlanRecord>
  >({});

  const handleSaveRecord = () => {
    const { id } = editorValues;
    const data = BillingPlanRecordInput.parse(editorValues);

    if (editorValues.id === 0) {
      createBillingPlanRecordMutation.mutate(data);
    } else {
      updateBillingPlanRecordMutation.mutate({ id: id!, ...data });
    }
  };

  const anyMutationLoading =
    createBillingPlanRecordMutation.isLoading ||
    updateBillingPlanRecordMutation.isLoading ||
    deleteBillingPlanRecordMutation.isLoading;

  // Nulls is always considered the latest end date
  const latestEndDate = billingPlanRecordQuery.data?.toSorted((a, b) => {
    if (a.endDate === null) return -1;
    if (b.endDate === null) return 1;
    return b.endDate.localeCompare(a.endDate);
  })[0]?.endDate;

  const records = billingPlanRecordQuery.data?.toSorted((a, b) =>
    a.startDate.localeCompare(b.startDate)
  ) || [];

  return (
    <>
      <Button
        onClick={() => {
          const earliestStartDate = head(records)?.startDate;
          const newRecord = {
            id: 0,
            billingPlanId: undefined,
            startDate: (latestEndDate
              ? dayjs(latestEndDate!).add(1, "month")
              : (earliestStartDate ? dayjs(earliestStartDate).add(-1, 'month') : dayjs())
            )
              .startOf("month")
              .format("YYYY-MM-DD"),
            endDate: null,
          };
          queryClient.setQueryData(
            ["billingPlanRecords", organizationId],
            (records: BillingPlanRecord[]) => [...records, newRecord]
          );
          setEditorValues(newRecord);
        }}
        style={{ float: "right", marginBottom: 16 }}
        type="primary"
      >
        Lisää uusi
      </Button>
      <Table
        bordered
        style={{
          pointerEvents: billingPlanRecordQuery.isLoading ? "none" : "auto",
        }}
        columns={[
          {
            title: "Hinnoittelumalli",
            dataIndex: "billingPlanId",
            key: "billingPlanId",
            width: "25%",
            render: (billingPlanId: string, record: BillingPlanRecord) =>
              record.id === editorValues.id ? (
                <Select
                  placeholder="Hinnoittelumalli"
                  value={editorValues.billingPlanId}
                  onChange={(value) =>
                    setEditorValues({
                      ...editorValues,
                      billingPlanId: value as string,
                    })
                  }
                  loading={billingPlansQuery.isLoading}
                  popupMatchSelectWidth={false}
                >
                  {billingPlansQuery.data?.filter(plan => plan.isActive).map((plan) => (
                    <Select.Option key={plan.id} value={plan.id}>
                      {plan.description}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                billingPlansQuery.data?.find((p) => p.id === billingPlanId)
                  ?.description
              ),
          },
          {
            title: "Alkaen",
            dataIndex: "startDate",
            key: "startDate",
            width: "25%",
            render: (startDate: string, record: BillingPlanRecord) =>
              record.id === editorValues.id ? (
                <DatePicker
                  value={dayjs(editorValues.startDate)}
                  picker="month"
                  allowClear={false}
                  onChange={(date) =>
                    setEditorValues({
                      ...editorValues,
                      startDate: date!.startOf("month").format("YYYY-MM-DD"),
                    })
                  }
                  disabledDate={(date) => {
                    // If end date is set, the allowed range is between the last end date before the currently set end date, and the currently set end date
                    if (editorValues.endDate) {
                      const latestEndDateBefore = findLast((r) => !!r.endDate && dayjs(r.endDate).isBefore(dayjs(editorValues.endDate)), records)?.endDate;
                      return date.isBefore(dayjs(latestEndDateBefore || "1970-01-01")) || date.isAfter(dayjs(editorValues.endDate));
                    } else {
                      // Otherwise check that the date does not fall into any existing record range
                      return records.filter(r => r.id).some((r) => 
                        !date.isBefore(dayjs(r.startDate)) && !date.isAfter(dayjs(r.endDate || "9999-12-31"))
                      );
                    }
                  }}
                />
              ) : (
                dayjs(startDate).format("YYYY-MM")
              ),
          },
          {
            title: "Päättyen",
            dataIndex: "endDate",
            key: "endDate",
            width: "25%",
            render: (endDate: string | null, record: BillingPlanRecord) =>
              record.id === editorValues.id ? (
                <DatePicker
                  value={
                    editorValues.endDate
                      ? dayjs(editorValues.endDate)
                      : undefined
                  }
                  picker="month"
                  placeholder="Ei koskaan"
                  allowClear={billingPlanRecordQuery.data
                    ?.filter((r) => r.id !== editorValues.id)
                    .every((r) => r.endDate !== null)}
                  onChange={(date) =>
                    setEditorValues({
                      ...editorValues,
                      endDate:
                        date?.endOf("month").format("YYYY-MM-DD") || null,
                    })
                  }
                  disabledDate={(date) =>{
                    // If the start date is set, the allowed range is between the start date and the next start date
                    if (editorValues.startDate) {
                      const firstStartDateAfter = find((r) => dayjs(r.startDate).isAfter(dayjs(editorValues.startDate)), records)?.startDate;
                      return date.isBefore(dayjs(editorValues.startDate)) || !date.isBefore(dayjs(firstStartDateAfter || "9999-12-31"));
                    } else {
                      // Otherwise check that the date does not fall into any existing record range
                      return records.filter(r => r.id).some((r) => 
                        !date.isBefore(dayjs(r.startDate)) && !date.isAfter(dayjs(r.endDate || "9999-12-31"))
                      );
                    }
                  }}
                />
              ) : endDate ? (
                dayjs(endDate).format("YYYY-MM")
              ) : (
                <Text style={{ opacity: 0.6 }} italic>
                  Ei koskaan
                </Text>
              ),
          },
          {
            title: "Toiminnot",
            key: "actions",
            width: "25%",
            render: (_: never, record: BillingPlanRecord) => (
              <div style={{ display: "flex", gap: 4, marginLeft: -12 }}>
                {record.id === editorValues.id ? (
                  <>
                    <Button
                      type="link"
                      onClick={() => {
                        setEditorValues({});
                        queryClient.setQueryData(
                          ["billingPlanRecords", organizationId],
                          (records: BillingPlanRecord[]) =>
                            records.filter((r) => r.id !== 0)
                        );
                      }}
                      disabled={anyMutationLoading}
                    >
                      Peruuta
                    </Button>
                    <Button
                      disabled={
                        anyMutationLoading ||
                        !editorValues.billingPlanId ||
                        !editorValues.startDate
                      }
                      type="link"
                      onClick={handleSaveRecord}
                    >
                      Tallenna
                    </Button>
                    <div style={{ flex: 1 }}></div>
                    <Button
                      type="link"
                      danger
                      disabled={anyMutationLoading || record.id === 0}
                      onClick={() =>
                        deleteBillingPlanRecordMutation.mutate(record.id)
                      }
                    >
                      Poista
                    </Button>
                  </>
                ) : (
                  <Button
                    disabled={typeof editorValues.id === 'number' || billingPlansQuery.isFetching}
                    type="link"
                    onClick={() => {
                      setEditorValues(record);
                    }}
                  >
                    Muokkaa
                  </Button>
                )}
              </div>
            ),
          },
        ]}
        dataSource={records.map((r) => ({ ...r, key: r.id }))}
      />
    </>
  );
};

export default BillingPlanRecordTable;

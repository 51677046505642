"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// JSX Structure Syntactic Sugar. Never reach the render code.
/* istanbul ignore next */
const DescriptionsItem = _ref => {
  let {
    children
  } = _ref;
  return children;
};
var _default = exports.default = DescriptionsItem;